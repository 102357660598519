<template>
  <div class="slider-setter" :class="{ disabled: !enabled }">
    <div class="header">
      <div class="title" :class="{ 'show-cursor': data.showCheckbox }" @click="handleCheck">
        <div v-if="data.showCheckbox" class="check" :class="{ checked: value > 0 }"></div>
        <img v-if="data.prefixIcon" :src="data.prefixIcon" alt="">
        <span>{{ onLang(data.title) }}</span>
      </div>
      <div class="tooltip">
        <span>{{ onLang(displayValue) }} {{ onLang(data.unit) }}</span>
        <div class="tip">
          <TooltipElement v-if="data.tooltip" :content="onLang(data.tooltip)" alwaysShow placement="bottom">
            <img src="@/assets/icons/Info_24.svg" alt="">
          </TooltipElement>
        </div>
      </div>
    </div>
    <div class="slider">
      <span class="min">{{ onLang(min) }}</span>
      <input
        type="range"
        :min="data.min"
        :max="data.max"
        class="range"
        :style="rangeStyle"
        :step="data.step || 1"
        v-model="range"
        @change="onChange"
      />
      <span class="max">{{ onLang(max) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SliderSetter',
  components: {
    TooltipElement: () => import('@/components/Base/TooltipElement.vue')
  },
  props: {
    data: Object,
    value: {
      type: [ Number, String ],
    },
    enabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {}
  },
  computed: {
    min() {
      return this.data.label ? this.data.label[this.data.min] : this.data.min
    },
    max() {
      return this.data.label ? this.data.label[this.data.max] : this.data.max
    },
    range: {
      get() {
        if (this.data.getUiValue) return this.data.getUiValue(this.value)
        return this.value
      },
      set(value) {
        let setVal = value
        if (this.data.getSetValue) {
          setVal = this.data.getSetValue(value)
        }
        this.$emit('input', Number(setVal))
      }
    },
    displayValue() {
      return this.data.label ? this.data.label[this.range] : this.range
    },
    rangeStyle() {
      return {
        '--range': (this.range - this.data.min) / (this.data.max - this.data.min) * 100 + '%'
      }
    },
  },
  methods: {
    onLang(str) {
      if (str && String(str).includes('$t_')) {
        return this.$t(str.replace('$t_', ''))
      } else {
        return str
      }
    },
    handleCheck() {
      if (this.data.showCheckbox) {
        const defaultVal = this.data.referenceValue ?
          this.data.referenceValue[this.data.default] :
          this.data.default
        this.$emit('input', this.value > 0 ? 0 : defaultVal)
      }
    },
    onChange(event) {
      this.range = event.target.value
      this.$emit('change', this.range)
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  user-select: none;
}
.slider-setter {
  background: #39425D;
  border-radius: 8px;
  position: relative;
  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; 
    padding: 8px 12px;
    border-bottom: 1px solid #4A5C78;
    box-sizing: border-box;
    span {
      font-size: px2rem(16);
      font-weight: 400;
      line-height: 24px;
      color: #ffffff;
    }
    .title {
      display: flex;
      align-items: center;
      
      img {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
      &.show-cursor {
        cursor: pointer;
      }
    }
    
    .tooltip {
      display: flex;
      align-items: center;
      .tip {
        width: 20px;
        height: 20px;
        margin-left: 12px;
        img {
          width: 20px;
          height: 20px;
          @include filter_FFF_50;
          cursor: pointer;
        }
      }
    }
  }

  .check {
    position: relative;
    width: 16px;
    height: 16px;
    border: 1px solid #ffffff;
    border-radius: 3px;
    margin-right: 8px;
    cursor: pointer;
    &::before {
      content: '';
      position: absolute;
      width: 7px;
      height: 12px;
      border-right: 2px solid #19223B;
      border-bottom: 2px solid #19223B;
      box-sizing: border-box;
      left: 4px;
      top: 0px;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
    }
    &.checked {
      background: #FFC600;
      border-color: #FFC600;
      &::before {
        transform: scale(1) rotate(45deg);
      }
    }
  }

  .slider {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 12px;
    box-sizing: border-box;

    span {
      min-width: 40px;
      font-size: px2rem(14);
      line-height: 24px;
      text-align: center;
      white-space: nowrap;
      color: #9D9D9D;
      &.min {
        margin-right: 7px;
      }
      &.max {
        margin-left: 7px;
      }
    }

    .divider {
      position: absolute;
      width: 3px;
      height: 6px;
      left: 25%;
      top: 18px;
      background: #282942;
    }

    .range {
      width: 100%;
      -webkit-appearance: none;
      vertical-align: middle;
      outline: none;
      border: none;
      padding: 0 2px;
      background: none;
      position: relative;
      /* z-index: 2; */
    }

    .range::-webkit-slider-runnable-track {
      background-color: #4A5C78;
      height: 6px;
      border-radius: 3px;
      background: linear-gradient(
        to right,
        #FFC600,
        #FFC600 var(--range),
        #4A5C78 var(--range),
        #4A5C78 100%
      );
    }

    .range[disabled]::-webkit-slider-runnable-track {
      opacity: 0.4;
    }

    .range::-moz-range-track {
      background-color: #d7dbdd;
      height: 6px;
      border-radius: 3px;
      border: none;
    }

    .range::-moz-range-thumb {
      border-radius: 50%;
      height: 16px;
      width: 16px;
      border: none;
      background: none;
      background-color: #ffffff;
    }

    .range:active::-moz-range-thumb {
      outline: none;
    }

    .range::-webkit-slider-thumb {
      -webkit-appearance: none !important;
      border-radius: 50%;
      background-color: #ffffff;
      height: 16px;
      width: 16px;
      margin-top: -5px;
      cursor: pointer;
    }

    .range[disabled]::-webkit-slider-thumb {
      background-color: #ffffff;
      border: 1px solid #d7dbdd;
      cursor: default;
    }

    .range:active::-webkit-slider-thumb {
      outline: none;
    }

    .range:hover::-webkit-slider-thumb {
      background: radial-gradient(circle at 65% 30%, white 2px, rgb(235, 231, 18) 3%, rgb(127, 127, 141) 80%, rgb(18, 235, 235) 100%);
    }
  }
}
</style>